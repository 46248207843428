require('select2');
require('flatpickr');
require('flatpickr/dist/l10n/es.js');
require('flatpickr/dist/themes/dark.css');
require('trumbowyg');
require('trumbowyg/dist/ui/trumbowyg.min.css');
require('trumbowyg/dist/langs/es.js');
require('../css/admin.css');

$(function () {
    //Select2
    $('.field_select2 select').select2();

    //FlatPicker
    $(".field_datetimepicker input.form-control").flatpickr({
        enableTime: true,
        enableSeconds: true,
        dateFormat: "d-m-Y H:i:S",
        time_24hr: true,
        locale: "es"
    });
    $(".field_datepicker input.form-control").flatpickr({
        dateFormat: "d-m-Y",
        locale: "es"
    });
    $(".field_timepicker input.form-control").flatpickr({
        enableTime: true,
        enableSeconds: true,
        dateFormat: "H:i:S",
        noCalendar: true,
        time_24hr: true,
        locale: "es"
    });

    //Trumbowyg
    $.trumbowyg.svgPath = '/build/icons.svg';
    $(".field_wysiwyg textarea.form-control").trumbowyg({
        lang: 'es'
    });
});